.ant-list-items {
  justify-content: center;
  flex-direction: row;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.question {
  scrollbar-width: none;
  width: 99%;

  .ant-list-item {
    width: 100%;
    display: block;
  }

  .question-item {
    display: inline-block !important;
    padding: 0px !important;
    break-inside: avoid !important;

    .question-index {
      display: block;
      break-after: avoid;
    }
  }
}

@media screen {
  .question-watermark {
    display: block;
    position: relative;
  }
  .question-watermark::after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 80;
    width: 100%;
    height: 100%;
    background-image: var(--watermark);
    background-repeat: repeat;
    background-size: 50%;
    opacity: 0.1;
  }
}

.brief-image {
  height: auto;
  width: 100%;
}

.ant-typography {
  width: 100%;
}
