html {
  --watermark: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 100'%3E %3Ctext x='120' y='50' fill='black' dominant-baseline='middle' text-anchor='middle' transform='rotate(30, 120, 50)' %3E myquestionbank.com %3C/text%3E %3C/svg%3E");
}

.pdf {
  display: none;
  margin: auto;
  width: 21cm;
  padding: 2.97cm 2.1cm;
  position: relative;
}

.pdf::after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;
  width: 100%;
  height: 100%;
  // background-image: var(--watermark);
  background-repeat: repeat;
  background-size: 50%;
  opacity: 0.1;
}

.pdf .ant-list-item {
  display: block;
}

@media print {
  .pdf {
    display: block;
    margin: auto;
    width: 21cm;
    padding: 0cm 2.1cm;
  }
  .question {
    .tags {
      display: none;
    }
  }

  .answer {
    padding-top: 1rem;
  }
}

@page {
  size: A4;
  margin: 2.97cm 0cm;
}
